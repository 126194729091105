.careers-container > .container {
    padding-top: 100px;
    padding-bottom: 100px;
}

@media screen and (min-width: 1023px) {
    .careers-container > .container {
        padding-left: 0;
    }
}

#careers-team {
    display: flex;
    align-items: center;
}

#careers-team .careers-details {
    margin-left: 5%;
}

#careers-team .careers-details p {
    white-space: pre-wrap;
    line-height: 24px;
}

#careers-team img {
    width: 45vw;
}

#careers-team p {
    font-size: var(--medium);
}

#careers-team li {
    font-size: var(--medium);
}

.career-list {
    margin-left: 0;
}

.career-list > li {
    font-size: var(--large);
    line-height: 40px;
    margin-left: -15px;
}

#of-req-container {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    position: relative;
}

.secondary-page-hero:has(#of-req-container) {
    padding-top: 120px;
    padding-bottom: 80px;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
    #of-req-container {
        width: 45vw;
    }
}

/* @media screen and (min-width: 1440px) and (max-width: 1920px) {
    #of-req-container {
        width: 40vw;
    }
} */

@media screen and (min-width: 1920px) {
    #of-req-container {
        width: 35vw;
    }
}

@media screen and (min-width: 2350px) {
    #of-req-container {
        width: 25vw;
    }
}

#of-req-container h2 {
    color: var(--white);
    font-size: var(--title-large);
}

#of-req-container .career-list {
    margin-bottom: 50px;
}

#of-req-container li {
    margin-top: 1em;
    font-size: var(--large);
    line-height: 40px;
    color: var(--white);
}

.careers-offer-request {
    width: 45%;
}

.vertical-line-container {
    width: 1px;
    padding: 25px 80px;
}

.vertical-line {
    width: 1px;
    background-color: var(--white);
    height: 88%;
    position: absolute;
    opacity: 0.8;
}

@media screen and (max-width: 1024px) {
    .careers-container > .container {
        padding-top: 0;
    }

    #careers-team {
        flex-direction: column;
        padding-bottom: 80px;
    }
    
    #careers-team img {
        width: 100vw;
        margin-bottom: 50px;
    }

    #careers-team .careers-details {
        margin-left: 0;
    }

    
    #of-req-container {
        flex-direction: column;
        align-items: start;
        justify-content: space-between;
        width: 100%;
    }

    .secondary-page-hero:has(#of-req-container) {
        padding-top: 100px;
        padding-bottom: 60px;
    }

    .careers-offer-request {
        width: 100%;
    }
    
    .vertical-line-container {
        width: 100%;
        padding: 0;
        padding-bottom: 60px;
    }
    
    .vertical-line {
        width: 100%;
        background-color: var(--white);
        height: 1px;
        position: absolute;
        opacity: 0.8;
    }
}