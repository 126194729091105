.contact-container {
    display: flex;
    justify-content: center;
    flex: 1;
}

.contact-form {
    max-width: 1000px;
    width: 50%;
    box-sizing: border-box;
    background-color: var(--white);
    box-shadow: 0px 8px 10px -1px rgba(0,0,0,0.1);
    border-radius: 8px;
    padding: 50px 10vw 50px;
    -webkit-box-shadow: 0 1px 10px rgba(110, 110, 110, 0.3);
    -moz-box-shadow: 0 1px 10px rgba(110, 110, 110, 0.3);
    box-shadow: 0 1px 10px rgba(110, 110, 110, 0.3);
    margin-bottom: 100px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-form > input, .contact-form > textarea {
    margin-bottom: 20px;
    padding: 12px 18px;
    background-color: var(--hover-grey);
    border: 1px solid var(--light-grey);
    border-radius: 4px;
    box-shadow: 1px 2px 2px -1px rgba(0,0,0,0.5);
    -webkit-box-shadow: 1px 2px 2px -1px rgba(0,0,0,0.5);
    -moz-box-shadow: 1px 2px 2px -1px rgba(0,0,0,0.5);
    outline: 0;
    resize: none;
    box-sizing: border-box;
    width: 100%;
}

.contact-form-header {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
}

.contact-form-header h1 {
    margin-right: 20px;
}

.contact-form > .input-title {
    font-size: var(--small);
    width: 100%;
    line-height: 28px;
    color: var(--dark-grey);
}

.contact-form > button {
    margin-top: 20px
}

.contact-form > input:focus, .contact-form > textarea:focus {
    box-shadow: 1px 3px 4px -1px rgba(0,0,0,0.5);
    -webkit-box-shadow: 1px 3px 4px -1px rgba(0,0,0,0.5);
    -moz-box-shadow: 1px 3px 4px -1px rgba(0,0,0,0.5);
}

.input-error {
    border: 1px solid var(--red) !important;
}

.length-text {
    align-self: flex-end;
    margin-top: -15px;
    color: var(--help-text);
    font-size: var(--tiny);
    margin-right: 3px;
}

.error {
    color: var(--red);
    margin-top: -15px;
    align-self: flex-start;
    font-size: var(--small);
    margin-bottom: 20px;
    margin-left: 3px;
}

@media screen and (max-width: 1024px) {
    .contact-form {
        width: 100%;
        box-shadow: none;
        margin-top: 20px;
    }

    .contact-form-header {
        flex-direction: column-reverse;
        align-items: center;
    }

    .contact-form-header h1 {
        font-size: var(--title-small);
        margin-top: 16px;
    }
}