footer {
    background-color: var(--dark-grey);
}
  
.footer-contact-section {
    background-color: var(--anthracite);
    display: flex;
    padding-top: 50px;
    padding-bottom: 50px;
    align-items: center;
    justify-content: center;
}

.footer-contact-section > p {
    color: var(--white);
    font-size: var(--title-small);
    margin-right: 50px;
}

.footer-link-section {
    padding: 80px 6vw 100px;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.footer-link-section h4, .footer-link-section h5, .footer-link-section a, .footer-link-section p {
    color: var(--white);
    text-decoration: none;
    font-weight: lighter;
    line-height: 30px;
    display: block;
}

.footer-link-section h4 {
    font-size: var(--medium);
    font-weight: 500;
    line-height: 40px;
}

.footer-link-section h5 {
    font-size: var(--medium);
    font-weight: 400;
    line-height: 35px;
}

.footer-link-section p, .footer-link-section a {
    font-size: var(--small);
    display: flex;
}

#footer-logo-container {
    position: absolute;
    left: 50px;
    top: 30px;
}   

#footer-logo-container img {
    width: 120px;
}

#footer-sitemap {
    display: grid;
    grid-template-columns: auto auto auto;
}

.subsection-container {
    padding: 0 20px;
}

.subsection-1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: -20px;
}

.subsection-2 {
    margin: 0 20px;
}

#social-media-container {
    display: flex;
    margin: -10px;
}

#social-media-container a {
    margin: 0 10px;
}

.contact-row {
    display: flex;
    align-items: center;
}

.contact-row p {
    margin-left: 10px;
}

#company-details > .subsection-container:not(:first-child) {
    margin-top: 20px;
}

#copyright {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
}

@media screen and (max-width: 1024px) {
    #footer-logo-container {
        position: relative;
        left: auto;
        top: auto;
        margin-bottom: 30px;
    }   

    .footer-contact-section {
        flex-direction: column;
    }

    .footer-contact-section > p {
        margin-right: 0;
        margin-bottom: 40px;
        text-align: center;
    }

    .footer-link-section {
        flex-direction: column;
    }

    #footer-sitemap {
        grid-template-columns: auto;
    }

    .subsection-container {
        margin-bottom: 30px;
        padding: 0;
    }

    #copyright {
        left: auto;
        transform: none;
    }
}