.products-wrapper {
    padding: 50px 0;
}

.product-container {
    display: flex;
    margin-bottom: 100px;
    justify-content: space-between;
    align-items: flex-start;
    scroll-margin-top: 80px;
}

.product-container-mirrored {
    flex-direction: row-reverse;
}

.product-with-image-container.product-container {
    display: block;
    margin-bottom: 150px;
}

.product-with-image-container > .product-container {
    margin-bottom: 80px;
}

.product-description-container {
    margin-right: clamp(30px, 6vw, 120px);
    max-width: 80%;
}

.product-container-mirrored .product-description-container {
    margin-right: 0;
    margin-left: clamp(30px, 6vw, 120px);
}

.product-image-container {

}

.product-title {
    margin-bottom: 16px;
}

.product-title a {
    text-decoration: none;
    color: inherit;
}

.product-description {
    line-height: 28px;
}

.product-specification-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: clamp(20px, 1.5vw, 40px);
}

.product-with-image-container .product-specification-container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 1024px) {
    .products-wrapper {
        padding: 30px 0;
    }
    
    .product-container {
        flex-direction: column;
        margin-bottom: 100px;
        align-items: normal;
    }

    .product-with-image-container.product-container {
        margin-bottom: 120px;
    }

    .product-description-container {
        width: 100%;
        max-width: none;
        margin-right: 0;
        margin-bottom: 50px;
    }
    
    .product-specification-container {
        grid-template-columns: 1fr 1fr;
        grid-gap: 16px;
    }

    .product-with-image-container .product-specification-container {
        grid-template-columns: 1fr 1fr;
    }
    
    .product-container-mirrored .product-description-container {
        margin-left: 0;
    }
}

@media screen and (max-width: 500px) {
    .product-specification-container {
        grid-gap: 8px;
    }
}

@media screen and (max-width: 300px) {
    .product-specification-container {
        grid-template-columns: 1fr;
    }
}