header {
    background-color: var(--white);
    display: flex;
    min-height: 64px;
    max-height: 66px;
    align-items: center;
    justify-content: end;
    padding: 0 clamp(30px, 3vw, 50px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 999;
    -webkit-box-shadow: 0 1px 10px rgba(110, 110, 110, 0.3);
    -moz-box-shadow: 0 1px 10px rgba(110, 110, 110, 0.3);
    box-shadow: 0 1px 10px rgba(110, 110, 110, 0.3);
}

.shadow {
    -webkit-box-shadow: 0 1px 10px rgba(110, 110, 110, 0.3);
    -moz-box-shadow: 0 1px 10px rgba(110, 110, 110, 0.3);
    box-shadow: 0 1px 10px rgba(110, 110, 110, 0.3);
}

.header-logo {
    margin-right: auto;
    height: 100%;
    display: flex;
    align-items: center;
}

.header-logo > img {
    object-fit: contain;
    height: 50px;
}

nav {
    display: flex;
}

.header-link {
    position: relative;
    text-decoration: none;
    padding: 0 20px;
    display: inline-block;
    line-height: 64px;
    user-select: none;
    font-family: montserrat, sans-serif;
}

.header-link > p {
    cursor: auto;
    font-family: montserrat, sans-serif;
}

.link-text {
    color: var(--black);
    font-size: var(--medium);
}

.submenu {
    display: none;
    position: absolute;
    top: 64px;
    left: 0;
    background-color: var(--white);
    grid-template-columns: auto auto;
    width: 30vw;
    min-width: 400px;
    max-width: 500px;
    padding: 10px 20px 20px;
    -webkit-box-shadow: 0 3px 5px rgba(110, 110, 110, 0.3);
    -moz-box-shadow: 0 3px 5px rgba(110, 110, 110, 0.3);
    box-shadow: 0 3px 5px rgba(110, 110, 110, 0.3);
    line-height: 48px;
    cursor: auto;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.submenu a {
    text-decoration: none;
    padding-left: 20px;
    margin-right: 20px;
    display: inline-block;
    color: var(--black);
    font-size: var(--medium);
    border-radius: 4px;
}

.submenu-section {
    display: flex;
    flex-direction: column;
}

.submenu-section-title {
    color: var(--help-text);
    font-size: var(--small);
    user-select: none;
}

.submenu-grid {
    display: grid;
}

@media screen and (min-width: 1025px) {
    .header-link:hover {
        cursor: pointer;
        background-color: var(--light-grey);
        border-top: var(--medium-grey) 2px solid;
        color: inherit;
    }

    .submenu a:hover {
        background-color: var(--light-grey);
    }

    .translation-dropdown > p:hover {
        background-color: var(--light-grey);
    }
}

.header-link.active {
    border-top: var(--red) 2px solid;
}

.active-link {
    border-top: var(--red) 2px solid !important;
}

.submenu a.active {
    background-color: var(--medium-grey);
}

.header-cta {
    padding: 10px 20px;
    border-radius: 8px;
    border: 0;
    background-color: var(--red);
    color: var(--white);
    font-weight: bold;
    box-shadow: 0px 5px 7px -3px rgba(0,0,0,0.15);
    margin-left: 10px;
    font-size: var(--small);
    transition: all ease 0.2s;
}

.translation-container {
    margin-left: 10px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: var(--small);
    user-select: none;
}

.translation-show {
    display: flex;
    align-items: center;
    padding: 15px 0px;
}

.translation-arrow {
    margin-top: 1px;
    margin-left: 2px;
    color: var(--black);
    font-size: var(--small);
}

.translation-show > p {
    margin-left: 5px;
    font-family: montserrat, sans-serif;
}

.translation-dropdown {
    display: none;
    position: absolute;
    z-index: 1000;
    background-color: var(--white);
    border-radius: 4px;
    top: 70px;
    text-align: center;
    overflow: hidden;
    width: 60px;
    -webkit-box-shadow: 0 1px 3px rgba(110, 110, 110, 0.3);
    -moz-box-shadow: 0 1px 3px rgba(110, 110, 110, 0.3);
    box-shadow: 0 1px 3px rgba(110, 110, 110, 0.3);
}

.translation-dropdown > p {
    padding: 10px;
    border-bottom: 1px solid var(--medium-grey);
}

#hamburger {
    display: none;
}

#mobile-header-cta {
    display: none;
}

/* MOBILE */
@media screen and (max-width: 1300px) {
    .cta.header-cta {
        display: none;
    }

    #hamburger {
        display: block;
    }

    header {
        padding: 0 10px;
    }

    .menu {
        display: flex;
        flex-direction: column;
        z-index: 999;
        position: absolute;
        width: 80vw;
        max-width: 350px;
        top: 64px;
        min-height: calc(100vh - 64px);
        height: auto;
        background-color: var(--white);
        right: -100%;
        transition: .3s right cubic-bezier(0.77, 0, 0.175, 1);
        overflow-y: auto;
        overflow-x: hidden;
        overscroll-behavior: contain;
        box-shadow: -2px 0px 3px -2px rgba(0,0,0,0.1);
        -webkit-box-shadow: -2px 0px 3px -2px rgba(0,0,0,0.1);
        -moz-box-shadow: -2px 0px 3px -2px rgba(0,0,0,0.1);
    }
    
    .submenu {
        width: 100%;
        height: auto;
        min-height: 100%;
        position: absolute;
        top: 0;
        right: -100%;
        left: auto;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        min-width: auto;
        box-sizing: border-box;
        box-shadow: none;
        border-radius: 0;
        background-color: var(--white);
        padding: 0;
        padding-bottom: 50px;
        transition: .3s right cubic-bezier(0.77, 0, 0.175, 1);
        overflow-y: auto;
    }

    .submenu > div {
        padding: 0 15px;
        box-sizing: border-box;
    }

    .open-menu {
        right: 0 !important;
    }

    .header-link {
        line-height: 60px;
        padding: 0 15px;
    }
    
    .active-link {
        padding: 0;
    }

    .header-link.active, .submenu-header.active {
        border-top: 0;
        border-bottom: var(--red) 2px solid;
        background-color: var(--light-grey);
    }

    .active-link {
        border-top: 0 !important;
        background-color: var(--light-grey);
    }

    .active-link > .link-text {
        padding: 0 15px;
        border-top: 0;
        border-bottom: var(--red) 2px solid;
        background-color: var(--light-grey);
    }

    #mobile-header-cta {
        display: block;
        margin-left: 0;
        margin: 50px 0;
        width: 85%;
        align-self: center;
        padding: 12px 0;
    }

    .submenu a {
        margin-right: 0;
        border-radius: 4px;
        line-height: 48px;
        padding: 0 20px;
    }

    .submenu-section {
        width: 100%;
    }

    .submenu-header {
        display: flex;
        align-items: center;
        background-color: var(--light-grey);
        border-bottom: var(--medium-grey) 2px solid;
        line-height: 60px;
    }

    .submenu-header .link-text {
        margin-left: 5px;
    }

    .translation-container {
        position: relative;
        right: 20px;
        align-self: self-end;
    }

    .translation-dropdown {
        right: 0;
        top: -260px;
        width: 80px;
    }

    .translation-dropdown > p {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}