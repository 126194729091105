.counter {
    font-size: var(--title-extra-large);
    color: var(--white);
    font-weight: bold;
}

.contact-btn {
    padding: 15px 30px;
    /* top-left top-right/bottom-left bottom-right */
    border-radius: 8px;
    border: 0;
    background-color: var(--red);
    color: var(--white);
    font-weight: bold;
    box-shadow: 0px 5px 7px -3px rgba(0,0,0,0.15);
    font-size: var(--large);
    transition: all ease 0.2s;
}

.product-header {
    display: flex;
    height: 20%;
    overflow: hidden;
    position: relative;
}

.product-header-details-container {
    display: flex;
    flex-direction: column;
    flex: 9;
    justify-content: center;
    user-select: none;
}

.overlay-gradient {
    background: rgba(230, 21, 37, 0.8);
    background: linear-gradient(90deg, rgba(161, 13, 88, 0.9) 10%, rgba(230, 21, 37, 1) 60%, rgba(230, 21, 37, 0.4) 70%, rgba(230, 21, 37, 0.2) 80%, rgba(230, 21, 37, 0) 100%);
    background: -webkit-linear-gradient(90deg, rgba(161, 13, 88, 0.9) 10%, rgba(230, 21, 37, 1) 60%, rgba(230, 21, 37, 0.4) 70%, rgba(230, 21, 37, 0.2) 80%, rgba(230, 21, 37, 0) 100%);
    background: -moz-linear-gradient(0deg, rgba(161, 13, 88, 0.9) 10%, rgba(230, 21, 37, 1) 60%, rgba(230, 21, 37, 0.4) 70%, rgba(230, 21, 37, 0.2) 80%, rgba(230, 21, 37, 0) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
}

.product-header-details-container {
    background-color: transparent;
    z-index: 200;
}

.product-header-details-container a {
    text-decoration: none;
    color: var(--white);
    margin-right: 10px;
}

.goto-gallery {
    display: flex;
    align-items: center;
    width: 35%;
    cursor: pointer;
    font-size: 22px;
    line-height: 36px;
}

.goto-gallery .gallery-arrow {
    font-size: 24px;
    margin-top: 2px;
}

.product-header-image-container {
    flex: 8;
}

.product-header-image-container img {
    aspect-ratio: 1.5;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.switch-container {
    display: flex;
    justify-content: center;
    padding: 50px 0;
}

.switch {
    align-self: center;
    background-color: var(--white);
    border-radius: 50px;
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
    display: inline-flex;
    min-width: 300px;
    overflow: hidden;
}

.option {
    -webkit-tap-highlight-color: transparent;
    padding: 24px 32px;
    text-decoration: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid var(--medium-grey);
    flex: 1;
    width: 33%;
    box-sizing: border-box;
    user-select: none;
}

.option.active {
    background-color: var(--selected-grey);
}

.option.active p {
    font-weight: 600;
}

.option:not(.active):hover {
    background-color: var(--hover-grey);
}

.option p {
    flex-wrap: wrap;
    user-select: none;
}

.specification-card {
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0px 4px 6px -2px rgba(0,0,0,0.5);
    -webkit-box-shadow: 0px 4px 6px -2px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 4px 6px -2px rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    aspect-ratio: 1;
    padding: 16px;
    box-sizing: border-box;
    border-top: 1px solid #eee;
    max-width: 12vw;
}

.product-with-image-container .specification-card {
    min-width: 0;
    max-width: none;
}

.specification-card:hover {
    background: rgba(230, 21, 37, 0.9);
    background: linear-gradient(215deg, rgba(230, 21, 37, 0.9) 0%, rgba(161, 13, 88, 0.8) 90%);
    background: -webkit-linear-gradient(215deg, rgba(230, 21, 37, 0.9) 0%, rgba(161, 13, 88, 0.8) 90%);
    background: -moz-linear-gradient(235deg, rgba(230, 21, 37, 0.9) 0%, rgba(161, 13, 88, 0.8) 90%);
}

.specification-card p {
    color: var(--dark-gray);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.specification-card:hover p {
    color: var(--white);
    text-shadow: 0px 0px 2px rgba(0,0,0,0.4);
}

.specification-card > img {
    height: 60%;
    aspect-ratio: 1;
}

.spec-name {
    margin-bottom: 8px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}

.spec-value {
    margin-top: 10px;
    font-weight: bold;
    font-size: var(--small);
    text-align: center;
    font-size: 16px;
}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 8px solid var(--medium-grey);
    border-top: 8px solid var(--red);
    border-radius: 50%;
    animation: spin 2s linear infinite;
    align-self: center;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 1024px) {
    .contact-btn {
        width: 100%;
        padding: 18px 0;
    }

    .product-header {
        flex-direction: column-reverse;
    }

    .overlay-gradient {
        background: linear-gradient(0deg, rgba(161, 13, 88, 1) 10%, rgba(230, 21, 37, 1) 60%, rgba(230, 21, 37, 0.4) 70%, rgba(230, 21, 37, 0.2) 80%, rgba(230, 21, 37, 0) 100%);
        background: -webkit-linear-gradient(0deg, rgba(161, 13, 88, 1) 10%, rgba(230, 21, 37, 1) 60%, rgba(230, 21, 37, 0.4) 70%, rgba(230, 21, 37, 0.2) 80%, rgba(230, 21, 37, 0) 100%);
        background: -moz-linear-gradient(90deg, rgba(161, 13, 88, 1) 10%, rgba(230, 21, 37, 1) 60%, rgba(230, 21, 37, 0.4) 70%, rgba(230, 21, 37, 0.2) 80%, rgba(230, 21, 37, 0) 100%);
        height: 75%;
        bottom: 0;
        top: auto;
    }
    
    .product-header-details-container {
        padding-bottom: 60px;
        padding-top: 40px;
    }

    .goto-gallery {
        width: 60%;
        font-size: 18px;
    }

    .goto-gallery .gallery-arrow {
        font-size: 22px;
        margin-top: 1px;
    }

    .switch-container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .switch {
        width: 100%;
        min-width: none;
        box-shadow: 0px 1px 3px 1px rgba(0,0,0,0.2);
        -webkit-box-shadow: 0px 1px 3px 1px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 1px 3px 1px rgba(0,0,0,0.2);
    }
   
    .option {
        padding: 20px 20px !important;
        max-width: none;
        min-width: auto;
    }

    .specification-card {
        background: rgba(230, 21, 37, 0.9);
        background: linear-gradient(215deg, rgba(230, 21, 37, 0.9) 0%, rgba(161, 13, 88, 0.8) 90%);
        background: -webkit-linear-gradient(215deg, rgba(230, 21, 37, 0.9) 0%, rgba(161, 13, 88, 0.8) 90%);
        background: -moz-linear-gradient(235deg, rgba(230, 21, 37, 0.9) 0%, rgba(161, 13, 88, 0.8) 90%);
        box-shadow: 0px 1px 3px -1px rgba(0,0,0,0.5);
        -webkit-box-shadow: 0px 1px 3px -1px rgba(0,0,0,0.5);
        -moz-box-shadow: 0px 1px 3px -1px rgba(0,0,0,0.5);
        border-top: 0;
        width: auto;
        height: auto;
        padding-left: 2px;
        padding-right: 2px;
        min-width: 0;
        max-width: none;
    }

    .specification-card > img {
        max-height: 40%;
    }

    .specification-card p {
        color: var(--white);
        text-shadow: 0px 0px 2px rgba(0,0,0,0.4);
    }
}

@media screen and (min-width: 500px) and (max-width: 1024px) {
    .specification-card > img {
        max-height: 50%;
    }
}

@media screen and (min-width: 500px) and (max-width: 700px) {
    .overlay-gradient {
        height: 60%;
    }
}

@media screen and (min-width: 700px) and (max-width: 1024px) {
    .overlay-gradient {
        height: 50%;
    }
}

@media screen and (max-width: 350px) {
    .overlay-gradient {
        height: 80%;
    }
}

@media screen and (max-width: 320px) {
    .overlay-gradient {
        height: 90%;
    }
}