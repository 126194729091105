@import './style/header.css';
@import './style/layouts.css';
@import './style/home.css';
@import './style/components.css';
@import './style/product.css';
@import './style/contact.css';
@import './style/partners.css';
@import './style/careers.css';
@import './style/footer.css';
@import './style/gallery.css';

body, #root, .root-layout {
  height: 100%;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "open-sans", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--white);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  color: var(--black);
}

h1, h2, h3, h4, h5, h6 {
  font-family: montserrat, sans-serif;
  font-style: normal;
}

p {
  font-family: "open-sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  white-space: pre-wrap;
}

button {
  -webkit-tap-highlight-color: transparent;
}

a {
  -webkit-tap-highlight-color: transparent;
}

img {
  pointer-events: none;
}

.section-title {
  font-size: var(--title-large);
}

main {
  background-color: var(--white);
  flex: 1;
  margin-top: 64px;
  height: 100%;
}

button {
  user-select: none;
}

.container {
  padding-right: clamp(160px, 12vw, 500px);
  padding-left: clamp(160px, 12vw, 500px);
}

/* KEEP HERE in case of new pages different of product ones but with same hero design */
/* page header (hero section of secondary pages), NOT the menu header */
.header-container { 
  padding-right: clamp(60px, 6vw, 100px);
  padding-left: clamp(60px, 6vw, 100px);
}

/* page header (hero section of secondary pages), NOT the menu header */
.text-header {
  font-size: var(--title-extra-large);
  color: var(--white);
  text-shadow: 0px 0px 3px rgba(0,0,0,0.4);
}

.secondary-page-hero {
  background: rgba(230, 21, 37, 0.8);
  background: linear-gradient(90deg, rgba(161, 13, 88, 0.9) 0%, rgba(230, 21, 37, 0.9) 100%);
  background: -webkit-linear-gradient(90deg, rgba(161, 13, 88, 0.9) 0%, rgba(230, 21, 37, 0.9) 100%);
  background: -moz-linear-gradient(90deg, rgba(161, 13, 88, 0.9) 0%, rgba(230, 21, 37, 0.9) 100%);
  width: 100%;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.secondary-page-hero h1, .secondary-page-hero h3 {
  color: var(--white)
}

.secondary-page-hero h1 {
 font-size: var(--title-extra-large);
}

.secondary-page-hero h3 {
  margin-top: 15px;
  text-align: center;
}

.secondary-page-hero > .contact-btn {
  background-color: var(--white);
  color: var(--red);
  margin-top: 50px;
}

/* progressive image loading  */
.loading {
  filter: blur(10px);
  clip-path: inset(0);
}

.loaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}

@media screen and (min-width: 1025px) {
  .cta:hover {
    cursor: pointer;
    box-shadow: 0px 8px 10px -1px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 8px 10px -1px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 8px 10px -1px rgba(0,0,0,0.1);
    transform: scale(1.05);
    border-radius: 26px 0 26px;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    padding-right: 20px;
    padding-left: 20px;
  }

  .header-container { 
    padding-right: 20px;
    padding-left: 20px;
  }

  .text-header {
    font-size: var(--title-large);
  }

  .secondary-page-hero {
    padding: 80px 20px 100px;
    box-sizing: border-box;
    background: linear-gradient(0deg, rgba(161, 13, 88, 0.9) 0%, rgba(230, 21, 37, 0.9) 100%);
    background: -webkit-linear-gradient(0deg, rgba(161, 13, 88, 0.9) 0%, rgba(230, 21, 37, 0.9) 100%);
    background: -moz-linear-gradient(0deg, rgba(161, 13, 88, 0.9) 0%, rgba(230, 21, 37, 0.9) 100%);
  }

  .secondary-page-hero h3 {
    margin-top: 30px;
  }
  
  .secondary-page-hero > .contact-btn {
    margin-top: 80px;
  }
}