.gallery-container {
    background: rgba(230, 21, 37, 0.8);
    background: linear-gradient(90deg, rgba(161, 13, 88, 0.9) 0%, rgba(230, 21, 37, 0.9) 100%);
    background: -webkit-linear-gradient(90deg, rgba(161, 13, 88, 0.9) 0%, rgba(230, 21, 37, 0.9) 100%);
    background: -moz-linear-gradient(90deg, rgba(161, 13, 88, 0.9) 0%, rgba(230, 21, 37, 0.9) 100%);
}

.gallery-header {
    min-height: 64px;
    max-height: 66px;
    background-color: rgba(255, 255, 255, 0.08);
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
}

.gallery-header p {
    color: var(--white);
    margin-left: 10px;
    font-size: var(--medium);
}

.back-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 100;
}

#title {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    font-size: var(--title-small);
}

#gallery-contact {
    cursor: pointer;
    text-decoration: none;
    color: var(--white);
    font-size: var(--medium);
    z-index: 100;
}

.mySwiper {
    height: auto;
    user-select: none;
    cursor: grab;
}

.mySwiper .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mySwiper p {
    color: var(--white);
}

.mySwiper img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.mySwiper .swiper-button-prev {
    color: var(--white);
    left: 0;
    text-shadow: 0px 1px 1px rgba(0,0,0,0.6);
    padding: 50px 20px;
    margin-top: -70px;
}

.mySwiper .swiper-button-next {
    color: var(--white);
    right: 0;
    text-shadow: 0px 1px 1px rgba(0,0,0,0.6);
    padding: 50px 20px;
    margin-top: -70px;
}

.mySwiper .swiper-pagination-bullet-active-main {
    background-color: var(--white)
}

@media screen and (max-width: 1024px) {
    #title {
        font-size: var(--large);
        right: auto;
        left: 40px;
        text-align: left;
        max-width: 40%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .mySwiper .swiper-button-prev {
        left: -10px;
    }
    
    .mySwiper .swiper-button-next {
        right: -10px;
    }
}