.hero-section {
    display: flex;
    width: 100%;
    overflow: hidden;
    position: relative;
    user-select: none;
    min-height: 300px;
}

.hero-section::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
}

.hero-image-container {
    position: relative;
    text-align: center;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in;
}

.home-hero-image {
    width: 100%;
    height: auto;
}

.hero-image-container#image-1-container > .hero-text-container {
    margin-left: -10%;
}

.hero-image-container#image-1-container {
    clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
    margin-right: -10%;
}

.hero-image-container#image-1-container::after {
    content:'';
    position: absolute;
    left: 0; 
    top: 0;
    width: 100%; 
    height: 100%;
    background-image: linear-gradient(305deg, var(--purple), rgba(161,13,88,0.8) 25%, rgba(161,13,88,0.6) 55%, rgba(161,13,88,0) 100%);
}

.hero-image-container#image-2-container {
    clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0% 100%);
}

.hero-image-container#image-2-container::after{
    content:'';
    position: absolute;
    left: 0; 
    top: 0;
    width: 100%; 
    height: 100%;
    background: linear-gradient(125deg, var(--red), rgba(230,21,37,0.8) 25%, rgba(230,21,37,0.6) 55%, rgba(230,21,37,0) 100%);
}

/* .hero-image-container#image-1-container:hover::after{
    background: linear-gradient(305deg, var(--purple), rgba(161,13,88,0.2) 65%, rgba(161,13,88,0) 100%);
}

.hero-image-container#image-2-container:hover::after{
    background: linear-gradient(125deg, var(--red), rgba(230,21,37,0.2) 65%, rgba(230,21,37,0) 100%);
} */

.hero-text-container {
    position: absolute;
    z-index: 100;
    margin: 0;
    top: 45%;
    left: 0;
    right: 0;
    padding: 0 20%;
}

.hero-text-container > h1, .hero-text-container > h4 {
    color: var(--white);
    text-shadow: 1px 1px 0px rgba(10,10,10,0.6);
}

.hero-text-container > h1 {
    font-size: 38px;
}

.hero-text-container > h4 {
    margin-top: 15px;
    text-align: left;
    line-height: 25px;
    width: 55%;
}

.about-us {
    text-align: center;
    align-items: center;
    margin: auto;
    width: 60%;
    padding-top: 50px;
    padding-bottom: 80px;
}

@media screen and (min-width: 1921px) {
    .about-us {
        width: 40%;
    }
}

.about-us > img {
    max-width: 200px;
    object-fit: contain;
    margin-bottom: 20px;
}

.about-us > p {
    padding-top: 30px;
    font-size: var(--large);
    line-height: 28px;
}

.statistics-container {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    padding: 100px 10%;
    position: relative;
    box-sizing: border-box;
    text-align: center;
    align-items: flex-start;
}

.statistics-container::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; 
    height: 100%;
    background: linear-gradient(235deg, rgba(230,21,37,0.7) 0, rgba(161,13,88,0.99) 100%);  
}

.stats-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 900;
    color: var(--white);
    width: 15%;
    max-width: 25%;
    text-shadow: rgba(0,0,0,0.3) 0px 0px 1px;
}

.stats-label {
    font-size: var(--title-small);
    color: var(--white);
}

.stats-prefix {
    font-size: var(--large);
}

.discover-us {
    width: 60%;
    margin: auto;
    padding: 100px 0;
    position: relative;
}

.discover-us > p {
    margin-top: 20px;
    margin-bottom: 40px;
    line-height: 24px;
    font-size: var(--medium);
    white-space: pre-wrap;
}

/* MOBILE */
@media screen and (max-width: 1024px) {
    .hero-section {
        flex-direction: column;
    }

    .hero-text-container {
        padding: 0 20px;
        text-align: left;
    }

    .hero-text-container > h1 {
        font-size: var(--title-large);
    }

    .hero-image-container > h1 {
        top: 0;
        bottom: 0;
        align-items: center;
        justify-content: center;
        display: flex;
        margin: auto;
    }

    #image-2-container > h1 {
        width: 40%;
    }

    .hero-image-container#image-1-container {
        clip-path: none;
    }
    
    .hero-image-container#image-1-container::after {
        background: linear-gradient(180deg, var(--red), rgba(230,21,37,0.2) 55%, rgba(230,21,37,0) 100%);
    }
    
    .hero-image-container#image-2-container {
        clip-path: none;
    }
    
    .hero-image-container#image-2-container::after {
        background-image: linear-gradient(180deg, var(--purple), rgba(161,13,88,0.2) 55%, rgba(161,13,88,0) 100%);
    }

    .hero-image-container#image-1-container {
        margin: 0
    }

    .hero-image-container#image-1-container > h1 {
        margin-left: 0;
    }

    .hero-text-container {
        margin-left: 0 !important;
        top: 15px;
        bottom: auto;
    }

    .about-us {
        width: 80%;
        padding-top: 70px;
        padding-bottom: 100px;
    }

    .about-us > img {
        max-width: 160px;
        margin-bottom: 20px;
    }

    .about-us > p {
        padding-top: 40px;
    }

    .statistics-container {
        flex-direction: column;
        align-items: center;
        padding-top: 80px;
        padding-bottom: 140px;
    }

    .stats-item {
        width: 80%;
        max-width: 90%;
        padding-top: 60px;
    }

    .discover-us {
        width: 80%;
        padding: 100px 0;
    }

    .discover-us > p {
        margin: 40px 0 60px;
    }

  
}