.partners-container > .container {
    padding-top: 100px;
    padding-bottom: 100px;
}

.partners {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#partners-intro {
    text-align: center;
    margin-bottom: 70px;
    line-height: 28px;
    font-weight: 600;
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.partners#intern {
    flex-direction: row-reverse;
    margin-top: 10%;
}

.partners h2 {
    text-align: center;
    font-size: 5vw;
    background-color: var(--red);
    background-image: linear-gradient(90deg, rgba(161, 13, 88, 0.9) 0%, rgba(230, 21, 37, 0.9) 100%);
    background-image: -webkit-linear-gradient(90deg, rgba(161, 13, 88, 0.9) 0%, rgba(230, 21, 37, 0.9) 100%);
    background-image: -moz-linear-gradient(90deg, rgba(161, 13, 88, 0.9) 0%, rgba(230, 21, 37, 0.9) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    background-size: 100%;
    background-repeat: repeat;
}

.partners p {
    max-width: 55%;
    font-size: var(--medium);
    margin-top: 15px;
    line-height: 24px;
}

#extern h2 {
    margin-right: 15%;
    margin-left: 5%;
}

#intern h2 {
    margin-left: 15%;
    margin-right: 5%;
}

@media screen and (max-width: 1024px) {
    .partners-container > .container {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .partners {
        flex-direction: column;
        align-items: flex-start;
    }

    #partners-intro {
        max-width: auto;
    }

    .partners#intern {
        flex-direction: column;
        margin-top: 100px;
    }

    .partners h2 {
        text-align: start;
        font-size: var(--title-large);
    }

    .partners p {
        max-width: none;
        font-size: var(--medium);
        margin-top: 30px;
    }

    #extern h2 {
        margin-right: 0;
        margin-left: 10px;
    }
    
    #intern h2 {
        margin-left: 10px;
        margin-right: 0;
    }
}